import {
  getAuth,
  createUserWithEmailAndPassword,
  signInWithPopup,
  GoogleAuthProvider,
  updateProfile,
  signOut,
  signInWithEmailAndPassword,
  onAuthStateChanged,
  getAdditionalUserInfo,
  getIdTokenResult,
  setPersistence,
  browserSessionPersistence,
   
  
  
} from "firebase/auth";
import { app } from "./config";

getAuth().useDeviceLanguage();
const projetcAuth = getAuth(app);
const provider = new GoogleAuthProvider();

export {
  projetcAuth,
  createUserWithEmailAndPassword,
  signInWithPopup,
  provider,
  updateProfile,
  signOut,
  signInWithEmailAndPassword,
  onAuthStateChanged,
  getAuth,
  getAdditionalUserInfo,
  getIdTokenResult,
  setPersistence,
  browserSessionPersistence
  
};
