import { useRef } from "react";

export default function useDebouce(myFunction, delay) {
  const timeoutRef = useRef(null);
  return function (...arg) {
    clearTimeout(timeoutRef.current);
    timeoutRef.current = setTimeout(() => {
      myFunction(...arg);
    }, delay);
  };
}
