import react from "react";
import * as functions_1 from "firebase/functions";
import * as material from "@mui/material";
import * as themeJsx from "../../../theme.jsx";
import * as firestore from "../../../firebase/firestore";
import * as firestore_1 from "firebase/firestore";
import { app } from "../../../firebase/config.js";
import { Delete } from "@mui/icons-material";
import { theme } from "../../../theme.jsx";
import InvalidDateGame from "./InvalidDateGame";
import ModalConfirmation from "../../../Componentes/UI/ModalConfirmation.jsx";

export default function UserJogos({
  multigamesSelection,
  deleteGame,
  clearAllGamesSelection,
}) {
  const functions = functions_1.getFunctions(app,"southamerica-east1");
  const [modal, setmodal] = react.useState(false);
  const [playGame, setplayGame] = react.useState(true);
  const [dataInicio, setdataInicio] = react.useState(null);
  const [dataFim, setdataFim] = react.useState(null);
  const [modalStatus, setModalStatus] = react.useState(false);

  react.useEffect(() => {
    const docRef = firestore.doc(
      firestore.projetcFirestore,
      `dataGame/XwkAegz0vJbpr7D8PaA5`
    );
    let unsubscribe  =  firestore.onSnapshot(docRef, (doc) => {
      let currentTime = firestore_1.Timestamp.now();
      setplayGame(
        Boolean(
          doc.data().inicio <= currentTime && currentTime <= doc.data().fim
        )
      );
      setdataInicio(() =>
        new Date(doc.data().inicio.seconds * 1000).toLocaleDateString()
      );
      setdataFim(() =>
        new Date(doc.data().fim.seconds * 1000).toLocaleDateString()
      );
    });
    unsubscribe()
  }, [playGame]);

  function sendGame(event) {
    event.preventDefault();
    if (playGame) {
      let jogos = [];
      multigamesSelection.forEach((arr, index) => {
        jogos[index] = arr.map((value) => Number(value));
      });

      let sendjogos = Object.assign({}, jogos);

      functions_1.httpsCallable(functions, "userGames")({ jogo: sendjogos });

      clearAllGamesSelection();
    } else {
      setmodal(true);
    }
  }

  return (
    <themeJsx.StyleGames>
      {modal && (
        <InvalidDateGame
          status={modal}
          changeStatus={setmodal}
          inicio={dataInicio}
          fim={dataFim}
        ></InvalidDateGame>
      )}
      <themeJsx.TitleUsers component={"h3"} align="center">
        Meus Jogos
      </themeJsx.TitleUsers>
      <material.List>
        {multigamesSelection.map((item, index) => {
          return (
            <material.ListItem key={index} alignItems="center" divider={true}>
              <material.List
                sx={{
                  paddingInlineStart: "4px",
                }}
              >
                {item.map((val, index) => {
                  return (
                    <material.ListItem key={index}>{val}</material.ListItem>
                  );
                })}
                <span style={{ position: "relative", insetBlockEnd: "3px" }}>
                  <Delete
                    fontSize="medium"
                    sx={{ color: theme.palette.error.main, cursor: "pointer" }}
                    onClick={() => deleteGame(index)}
                  ></Delete>
                </span>
              </material.List>
            </material.ListItem>
          );
        })}
      </material.List>
      <material.Button
        disabled={multigamesSelection.length === 0}
        sx={{
          color: "white",
          border: "none",
          fontSize: "19px",
          cursor: "pointer",
          blockSize: "35px",
        }}
        variant="contained"
        color="primary"
        onClick={(e) => setModalStatus(true)}
      >
        Jogar
      </material.Button>
      {modalStatus && (
        <ModalConfirmation
          style={{ position: "absolute" }}
          status={true}
          changeStatus={setModalStatus}
          text={"Tem certeza que deseja salvar esse jogo? Você será redirecionado para o whatsapp "}
          tringerFunction={sendGame}
        />
      )}
    </themeJsx.StyleGames>
  );
}
