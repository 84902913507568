import react from 'react';
import * as material from '@mui/material';
import  {theme} from "../../theme.jsx";
import * as themePage from "../../theme"
import * as reactRouterDom from "react-router-dom";
import useAuthContext from "../../hooks/useAuthContext.jsx";
import getAllGameDataHook from "../../hooks/useGetallGame.jsx";
import ListNumberShowAcertos from "../../Componentes/UI/ListNumberShowAcertos";

export default function ResultadoGeral() {
  const [allgames, setallgames] = react.useState([]);
  const [isPending, setIsPending] = react.useState(true);
  const { user } = useAuthContext();

  const columns = [
    {
      field: "apelido",
      headerName: "apelido",
    },
    {
      field: "jogo",
      headerName: "jogos",
      type: "number",
      renderCell: (params) => (
        <ul
          style={{
            display: "flex",
            inlineSize: "100%",
            flexDirection: "column",
            listStyle: "none",
            
          }}
        >
         
          {params.value.jogo.map((jogos, index) => (
            <material.Typography
              component="li"
              sx={{
                display: "flex",
                inlineSize: "100%",
                justifyContent: {
                  xs: "space-evenly",
                  sm: "space-around",
                  md: "space-around",
                  lg: "space-around",
                },
                flexWrap: "wrap",
                marginBlockEnd: "8px",
                borderBlockEndColor: "rgba(100,100,100,0.5)",
                borderBlockEndStyle: "solid",
                borderBlockEndWidth: "0.5px",
              }}
              key={index}
            >
             
              {jogos.map((number, index) => (
                <material.Typography
                  component="ul"
                  
                  sx={{
                    blockSize:'24px',
                    fontSize: {
                      xs: "12px",
                      sm: "inherit",
                      md: "inherit",
                      lg: "inherit",
                      listStyleType:'none'
                    },
                  }}
                  key={index}
                >
                  { params.value.type === true ?
                    <ListNumberShowAcertos
                      paddStyle= {"3px"}
                      numero={number.value}
                      selecao={number.selection}
                      back={`${theme.palette.primary.main}`}
                      backselect={theme.palette.laranja.main}
                    /> :<span>{number}</span>
                  }
                  
                </material.Typography>
              ))}
            </material.Typography>
          ))}
        </ul>
      ),
    },

    {
      field: "pontos",
      headerName: "pontos",
      type: "number",
      // width: 50,
      renderCell: (params) => (
        <material.Typography
          component="ul"
          sx={{
            display: "flex",
            inlineSize: "100%",
            flexDirection: "column",
            listStyle: "none",
            blockSize: "100%",
            justifyContent: {
              xs: "space-around",
              sm: "space-around",
              md: "space-evenly",
              lg: "space-evenly",
            },
          }}
        >
          {params.value.map((ponto, index) => (
            <material.Typography
              component="li"
              sx={{
                display: "flex",
                inlineSize: "100%",
                justifyContent: "space-around",
                marginBlockEnd: "8px",
                fontSize: {
                  xs: "12px",
                  sm: "inherit",
                  md: "inherit",
                  lg: "inherit",
                },
              }}
              key={index}
            >
              {ponto}
            </material.Typography>
          ))}
        </material.Typography>
      ),
    },
  ];

  const rows = allgames.map((user) => {
    //alteração para acertos ao inves de jogos para hablitar cor nos acertos
  let [jogo, type] =  jogosOuAcertos(user)
  return {
      id: user.uid,
      apelido: user.apelido,
      jogo: {jogo,type},
      
      pontos: Object.values(user.pontos),
    };
  });

  function jogosOuAcertos(usuario){
   let acerto =  Object.values(usuario.acertos) 
   let jogo =  Object.values(usuario.jogo) 
   if(Boolean(acerto.length)){
    return [acerto, true]
   }else{
    return [jogo,false]
   }
  }
  
  react.useEffect(() => {
    (async () => {
      let data = await getAllGameDataHook();
      setallgames(data);

     
    })();
    setIsPending(false);
  }, []);

  
  
 

  return (
    <>
      {!user && <reactRouterDom.Navigate to="/" replace={true} />}
      {isPending && user && <material.CircularProgress color="secondary" />}
      {!isPending && user && (
        <section
          style={{
            blockSize: "calc(100% - 70px)",
            display: "flex",
            inlineSize: "100%",
            flexDirection: "column",
          }}
        >
          <material.Box
            sx={{
              blockSize: "100%",
              inlineSize: "100%",
              backgroundColor: theme.palette.secundary.main,
            }}
          >
            <themePage.StyleDataGrid
              sx={{ zIndex: "2" }}
              rows={rows}
              getRowId={(row => row.id)}
              columns={columns}
              pageSize={allgames.length}
              disableSelectionOnClick
              getRowHeight={({ densityFactor }) => 100 * densityFactor}
              rowsPerPageOptions={[allgames.length]}
            />
          </material.Box>
        </section>
      )}
    </>
  );
}
