import * as material from "@mui/material";
import { Link } from "react-router-dom";
import { DataGrid } from "@mui/x-data-grid";

export const theme = material.createTheme({
  palette: {
    primary: {
      main: "#030041",
    },
    secundary: {
      main: "#2f3d8f",
    },
    error: {
      main: "#EB381F",
    },
    laranja: {
      main: "#EB761F",
    },
    textLight: {
      main: "#FFFFFF",
    },
    textDard: {
      main: "#2f3d8f",
    },
  },
  typography: {
    fontSize: 16,
  },
});

export const TitleUsers = material.styled(material.Typography)(({ theme }) => ({
  fontWeight: "900",
  color: theme.palette.secundary.main,
  fontSize: theme.typography.fontSize,
}));

export const StyleList = material.styled(material.List)({
  listStyleType: "none",
  display: "grid",
  gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr",
  rowGap: "3px",
  justifyItems: "center",
  fontSize: "1rem",
  inlineSize: "100%",
  blockSize: "85%",
  "& + p": {
    paddingBlockEnd: "8px",
    textAlign: "center",
    inlineSize: "100%",
    blockSize: "5%",
  },
});

export const StyleSelectNumber = material.styled(material.ListItemButton)(({ theme }) => ({
  display: "block",
  aspectRatio: "1",
  backgroundColor: theme.palette.laranja.main,
  borderRadius: " 100%",
  inlineSize: " 30px",
  blockSize: " 30px",
  borderColor: theme.palette.textDard.main,
  borderStyle: "solid",
  borderWidth: "1px",
  textAlign: " center",
  cursor: " pointer",
  paddingBlockStart: " 2px",
  paddingInlineStart:'5px',
  color: " white",
  fontWeight: "700",
  "&.Mui-selected": {
    backgroundColor: theme.palette.primary.main,
  },
  "&.MuiListItemButton-root:hover": {
    backgroundColor: theme.palette.secundary.main,
  },
}));

export const StyleDataGrid = material.styled(DataGrid)({
  margin: "solid",
  blockSize:'100%',
  inlineSize: "100%",
  color: "white",
  "div:nth-of-type(3) > .MuiDataGrid-footerContainer": {
    display: "none",
  },
  "& .MuiDataGrid-main": {
    inlineSize: "inherit",
    "& .MuiDataGrid-columnHeaders": {
      inlineSize: "inherit",
      "& .MuiDataGrid-columnHeadersInner": {
        inlineSize: "inherit",
        "& > div": {
          inlineSize: "inherit",
          "& > div:nth-of-type(1)": {
            [theme.breakpoints.down("md")]: {
              inlineSize: "25%!important",
              minWidth: "60px!important",
              maxWidth: "25%!important",
              width: "25%!important",
            },
            [theme.breakpoints.up("md")]: {
              inlineSize: "20%!important",
              minWidth: "20%!important",
              maxWidth: "20%!important",
              width: "20%!important",
            },
            "& .MuiDataGrid-columnHeaderTitleContainer": {
              [theme.breakpoints.down("md")]: {
                justifyContent: "flex-start",
              },
              justifyContent: "center",
            },
          },
          "& > div:nth-of-type(2)": {
            [theme.breakpoints.down("md")]: {
              inlineSize: "60%!important",
              minWidth: "60%!important",
              maxWidth: "60%!important",
              width: "60%!important",
            },
            [theme.breakpoints.up("md")]: {
              inlineSize: "70%!important",
              minWidth: "70%!important",
              maxWidth: "70%!important",
              width: "70%!important",
            },
            "& .MuiDataGrid-columnHeaderTitleContainer": {
              justifyContent: "center",
            },
          },
          "& > div:nth-of-type(3)": {
            [theme.breakpoints.down("md")]: {
              inlineSize: "20%!important",
              minWidth: "20%!important",
              maxWidth: "20%!important",
              width: "20%!important",
            },
            [theme.breakpoints.up("md")]: {
              inlineSize: "10%!important",
              minWidth: "10%!important",
              maxWidth: "10%!important",
              width: "10%!important",
            },
            "& .MuiDataGrid-columnHeaderTitleContainer": {
              [theme.breakpoints.down("md")]: {
                justifyContent: "flex-start",
              },
              justifyContent: "center",
            },
          },
        },
      },
    },
    "& .MuiDataGrid-virtualScroller": {
      inlineSize: "100%",

      "& >div": {
        inlineSize: "100%",
        "& >div": {
          inlineSize: "100%",
          blockSize: "fit-content",
          "& >div": {
            inlineSize: "100%",
            blockSize: "fit-content",
            maxHeight: "100%!important",
            "& > div:nth-of-type(1)": {
              alignItems: "flex-start",
              paddingBlockStart: "15px",
              paddingInline:'0px'
            },
            "& > div": {
              inlineSize: "22%!important",
              minWidth: "60px!important",
              maxWidth: "22%!important",
              width: "22%!important",
              justifyContent: "center",
              maxHeight: "100%!important",
              [theme.breakpoints.down("sm")]: {
                fontSize: "12px",
              }
            },
            "& > div:nth-of-type(2)": {
              inlineSize: "68%!important",
              minWidth: "68%!important",
              maxWidth: "68%!important",
              width: "68%!important",
              justifyContent: "center",
              maxHeight: "fit-content !important",
              paddingInline:'0px'
            },
            "& > div:nth-of-type(3)": {
              inlineSize: "10%!important",
              minWidth: "10%!important",
              maxWidth: "10%!important",
              width: "10%!important",
              justifyContent: "center",
              maxHeight: "fit-content !important",
              paddingInline:'0px'
            },
            "& > div:nth-of-type(4)": {
              display: "none",
            },
          },
        },
      },
    },
  },
});

export const StyleDayWinNumber = material.styled(material.ListItemButton)(({ theme,back,backselect,p}) => ({
  display: "inline",
  aspectRatio: "1",
  backgroundColor: back,
  borderRadius: " 100%",
  maxInlineSize: "30px",
  blockSize: " 30px",
  borderColor: theme.palette.textDard.main,
  borderStyle: "solid",
  borderWidth: "1px",
  cursor: " pointer",
  padding: p,
  color: " white",
  fontWeight: "500",
  fontSize:'16px',
  pointerEvents: "none",
  "&.Mui-selected": {
    backgroundColor: backselect,
  },
}));

export const StyleGames = material.styled("section")(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    marginBlock: "16px",
  },
  display: "flex",
  position: "relative",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "space-between",
  alignContent: "center",
  blockSize: "100%",
  inlineSize: "100%",
  border: "solid 1px gray",
  paddingBlock: "6px",

  "& .MuiListItem-root": {
    paddingBlockStart: "0px",
    paddingBlockEnd: "1px",
    paddingInlineStart: "0px",
    paddingInlineEnd: "0px",
    justifyContent: "center",
    marginInline: "2px",
  },
  "& > ul": {
    display: "flex",
    position: "absolute",
    blockSize: "81%",
    insetBlockStart: "50%",
    insetInlineStart: "50%",
    transform: "translate(-50%,-50%)",
    insetBlockEnd: "8%",
    flexDirection: "column",
    inlineSize: "100%",
    fontSize: "16px",
    alignItems: "center",
    justifyContent: "center",
    marginBlockEnd: "5px",
    overflowY: "scroll",

    "& > li": {
      inlineSize: "100%",
      "& > ul": {
        display: "flex",
        inlineSize: "100%",
        justifyContent: "space-evenly",
        "& > li": {
          backgroundColor: theme.palette.primary.main,
          blockSize: "26px",
          inlineSize: "29px",
          borderRadius: "100%",
          textAlign: "center",
          fontWeight: "600",
          color: "white",
          paddingInlineStart: "0px",
        },
      },
    },
  },
}));

export const StyledLink = material.styled(Link)({
  textDecoration: "none",
  color: "inherit",
  textTransform: "uppercase",
  fontSize: "1.1rem",
});

export const InforHome = material.styled(material.Container)(({ theme }) => ({
  alignItems: "center",
  [theme.breakpoints.down("sm")]: {
    inlineSize: "90%",
    blockSize: "85%",
    textAlign: "center",

    "& > div": {
      blockSize: "100%",
      justifyContent: "space-evenly",
    },
    "& button": {
      inlineSize: "100%",
    },
    "& a": {
      color: "white",
      textDecoration: "none",
    },
  },
  [theme.breakpoints.up("sm")]: {
    inlineSize: "70%",
    blockSize: "85%",
    textAlign: "center",

    "& > div": {
      blockSize: "100%",
      justifyContent: "space-evenly",
      alignItems: "center",
    },
    "& button": {
      inlineSize: "100%",
    },
    "& a": {
      color: "white",
      textDecoration: "none",
    },
  },
}));

export const ModalUser = material.styled(material.Modal)({
  insetBlockStart: "3%",
  insetInlineStart: "15%",
  insetInlineEnd: "15%",
  blockSize: "95%",

  backgroundColor: theme.palette.secundary.main,
  alignItems: "center",
  border: "none",
});

export const SectionCadastro = material.styled("form")(({ theme }) => ({
  blockSize: "100%",
  marginInline: "auto",

  [theme.breakpoints.down("md")]: {
    inlineSize: "65%",
  },
  [theme.breakpoints.up("md")]: {
    inlineSize: "65%",
  },
}));

export const FormLogin = material.styled("form")(({ theme }) => ({
  blockSize: "100%",
  marginInline: "auto",

  [theme.breakpoints.down("md")]: {
    inlineSize: "55%",
  },
  [theme.breakpoints.up("md")]: {
    inlineSize: "30%",
  },
}));

export const PalpiteStyle = material.styled(material.Container)({
    display: "flex",
    flexDirection: "column",
    blockSize: "16%",
    inlineSize: "100%",
    paddingInline: "0px",
    paddingBlockStart:'8px',
    justifyContent: "center",
    backgroundColor:`${theme.palette.secundary.main}`,
   
    maxWidth: "100% !important",
    "& div": {
      inlineSize: "inherit",
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
      "& span": {
        marginInlineEnd: "auto",
        fontWeight: "600",
      },
      "& .MuiList-root": {
        display: "inline-flex",
        flexDirection: "row",
        inlineSize: "80%",
        flexWrap: "wrap",
        padding: "0",
        justifyContent: "space-around",

        "& li": {
          paddingInline: "1px",
          marginInline: "1px",
          borderWidth: "1px",
          borderColor: "black",
          color: "white",
          borderStyle: "solid",
          fontWeight: "bold",
          justifyContent: "center",
          inlineSize: "min-content",
        },
      },
    },
  });