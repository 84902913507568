import react from "react";
import { StyleSelectNumber } from "../../../theme.jsx";

function ListOfNumbersMem({
  numero,
  reset,
  setreset,
  setuserGameSelection,
  userGameSelection,
  setnumbers,
}) {
  //Codigo precisa ser melhorado, muitas renderizações
  //const [select, setselect] = useState(false);

  react.useEffect(() => {
    if (reset === true) {
      //setselect(false);
      setreset(false);
    }
  }, [reset, setreset]);

  function userGame(newvalue) {
    let sizeGame = userGameSelection.length;
    if (sizeGame < 10) {
      setnumbers((prev) => {
        return prev.map((numb) => {
          if (numb.value === newvalue.value) {
            return newvalue.selection === true
              ? { ...numb, selection: false }
              : { ...numb, selection: true };
          } else {
            return numb;
          }
        });
      });
    } else {
      setnumbers((prev) => {
        return prev.map((numb) => {
          if (numb.value === newvalue.value) {
            return { ...numb, selection: false };
          } else {
            return numb;
          }
        });
      });
    }

    setuserGameSelection((oldData) => {
      let size = oldData.length;
      switch (true) {
        case size > 9:
          return oldData.includes(newvalue.value)
            ? oldData.filter((item) => item !== newvalue.value)
            : oldData;

        default:
          return !oldData.includes(newvalue.value)
            ? [...oldData, newvalue.value]
            : oldData.filter((item) => item !== newvalue.value);
      }
    });
  }

  return (
    <StyleSelectNumber
      component="li"
      selected={numero.selection}
      divider={true}
      value={numero}
      onClick={() => userGame(numero)}
    >
      {numero.value}
    </StyleSelectNumber>
  );
}

export const ListOfNumbers = react.memo(ListOfNumbersMem);
