import react from "react";
import * as reactRouterDom from "react-router-dom";
import * as material from "@mui/material";
import * as themeJsx from "../../../theme.jsx";
import * as firestore from "../../../firebase/firestore";
import * as auth from "../../../firebase/auth";
import useAuthContext from "../../../hooks/useAuthContext";
import UserCadastro from "../Cadastro/UserCadastro";
import { ListOfNumbers } from "./ListOfNumbers";
import UserJogos from "./UserJogos.jsx";
import uniqid from "uniqid";

const orientacoes = [
  "1. Escolha 10 numeros e clique em selecionar ou selecione a opção aleatórios.",
  "2. Você verá seus jogos no campo 'Meus Jogos'.",
  "3. Caso queira excluir algum jogo, basta clicar na lixeira vermelha.",
  "4. Verifique se todos os jogos estão corretos, depois desse passo não será mais possivel exclui-los.",
  "5. Clique na opção 'Jogar'",
];

export default function User() {
  const { user, dispatch } = useAuthContext();
  const [multigamesSelection, setmultigamesSelection] = react.useState([]);
  const [reset, setreset] = react.useState(false);
  const [userGameSelection, setuserGameSelection] = react.useState([]);
  const [numbers, setnumbers] = react.useState([]);
  const [dates, setdates] = react.useState({});
  const GamePrice = 20;
  const media = window.matchMedia("(max-width: 900px)").matches;

  function convertData(data) {
    return new Date(data * 1000).toLocaleDateString("pt-br", {
      month: "2-digit",
      day: "2-digit",
    });
  }


  

  react.useEffect(() => {
    (async function hasWinner() {
     
      auth
        .getIdTokenResult(user)
        .then((data) =>
          dispatch({ type: "isAdmin", payload: data.claims.admin })
        );
      let dataGameRef = firestore.doc(
        firestore.projetcFirestore,
        "/dataGame/XwkAegz0vJbpr7D8PaA5"
      );
      let rodarGameRef = firestore.doc(
        firestore.projetcFirestore,
        "/rodarJogo/HZbA57wRjFmgZBOLHYSE"
      );

      let dataGameDoc = await firestore.getDoc(dataGameRef);
      let rodarGameDoc = await firestore.getDoc(rodarGameRef);

      setdates({
        inicioAposta: convertData(dataGameDoc.data().inicio.seconds),
        fimAposta: convertData(dataGameDoc.data().fim.seconds),
        rodarJogo: convertData(rodarGameDoc.data().inicio.seconds),
      });
    })();
  }, [dispatch]);

  react.useMemo(() => {
    const QUINA = () => {
      let aux = [
        { value: "01", selection: false },
        { value: "02", selection: false },
        { value: "03", selection: false },
        { value: "04", selection: false },
        { value: "05", selection: false },
        { value: "06", selection: false },
        { value: "07", selection: false },
        { value: "08", selection: false },
        { value: "09", selection: false },
      ];
      for (let i = 10; i < 81; i++) {
        aux.push({ value: i, selection: false });
      }
      return aux;
    };

    setnumbers(QUINA);
  }, []);

  function salveMyGame(event) {
    event.preventDefault();
    if (Boolean(userGameSelection.length === 10)) {
      setmultigamesSelection((prev) => [
        ...prev,
        userGameSelection.sort((a, b) => a - b),
      ]);
      setreset(true);
      setuserGameSelection([]);
      setnumbers((prev) =>
        prev.map((numb) => {
          return { ...numb, selection: false };
        })
      );
    }
  }

  function deleteGame(index) {
    setmultigamesSelection((previ) => {
      return previ.filter((itens) => previ.indexOf(itens) !== index);
    });
  }

  function clearAllGamesSelection() {
    setmultigamesSelection([]);
  }

  function LayoutNumbers() {
    return (
      <material.Grid
        container
        sx={{
          padding: "6px",
          justifyContent: "center",
          blockSize: "100%",
        }}
      >
        <themeJsx.TitleUsers component={"h3"} align="center">
          Preencha sua aposta
        </themeJsx.TitleUsers>
        <themeJsx.StyleList>
          {numbers.map((numero) => {
            return (
              <ListOfNumbers
                key={uniqid()}
                userGameSelection={userGameSelection}
                setuserGameSelection={setuserGameSelection}
                numero={numero}
                reset={reset}
                setreset={setreset}
                setnumbers={setnumbers}
              ></ListOfNumbers>
            );
          })}
        </themeJsx.StyleList>
        <p>
          Você selecionou{" "}
          {userGameSelection.length <= 10 ? userGameSelection.length : 10} do
          total de 10
        </p>
        <material.Box sx={{ display: "flex" }}>
          <material.Button
            variant="contained"
            color="primary"
            disabled={userGameSelection.length !== 10}
            sx={{
              color: "white",
              border: "none",
              fontSize: { xs: "1rem", sm: "19px" },
              cursor: "pointer",
              blockSize: "35px",
            }}
            onClick={(e) => salveMyGame(e)}
          >
            Selecionar
          </material.Button>
          <material.Button
            variant="contained"
            color="error"
            sx={{
              color: "white",
              border: "none",
              fontSize: { xs: "1rem", sm: "19px" },
              cursor: "pointer",
              blockSize: "35px",
              marginInlineStart: "18px",
            }}
            onClick={(e) => {
              let aux = new Array(10);

              for (let i = 0; i < 10; i++) {
                let number = Math.floor(Math.random() * 79) + 1;
                switch (true) {
                  case number === 1:
                    number = "01";
                    break;
                  case number === 2:
                    number = "02";
                    break;
                  case number === 3:
                    number = "03";
                    break;
                  case number === 4:
                    number = "04";
                    break;
                  case number === 5:
                    number = "05";
                    break;
                  case number === 6:
                    number = "06";
                    break;
                  case number === 7:
                    number = "07";
                    break;
                  case number === 8:
                    number = "08";
                    break;
                  case number === 9:
                    number = "09";
                    break;
                  default:
                    break;
                }
                if (!aux.includes(number)) {
                  aux[i] = number;
                } else {
                  i--;
                }
              }

              setmultigamesSelection((prev) => [
                ...prev,
                aux.sort((a, b) => a - b),
              ]);
            }}
          >
            Aleatórios
          </material.Button>
        </material.Box>
      </material.Grid>
    );
  }

  function Dateshandler() {
    return (
      <react.Fragment>
        <material.Typography component="p" align="center">
          Periodo de aposta:{" "}
          {<material.Typography component="span" sx={{
            color:themeJsx.theme.palette.laranja.main,
            fontWeight:'700'
          }}>{dates.inicioAposta}</material.Typography>}{" "}
           a{" "}
          {
            <material.Typography component="span" sx={{
              color:themeJsx.theme.palette.laranja.main,
              fontWeight:'700'
            }}>
              {dates.fimAposta}
            </material.Typography>
          }
        </material.Typography>
        <material.Typography component="p" align="center">
          Inicio do Sorteio:{" "}
          {
            <material.Typography component="span" sx={{
              color:themeJsx.theme.palette.laranja.main,
              fontWeight:'700'
            }}>
              {dates.rodarJogo}
            </material.Typography>
          }
        </material.Typography>
      </react.Fragment>
    );
  }
 
  return (
    <react.Fragment>
      {user && <UserCadastro uid={user.uid}></UserCadastro>}

      {user && !media && (
        <material.Grid
          container
          columnSpacing={1}
          columns={{ md: 12, xs: 4 }}
          sx={{
            paddingBlockStart: "6px",
            paddingBlockEnd: "2px",
            blockSize: "80%",
            flexDirection: "row",
          }}
        >
          <material.Grid item md={5} xs={4} sx={{ blockSize: "100%" }}>
            {<LayoutNumbers />}
          </material.Grid>
          <material.Grid
            item
            md={4}
            xs={4}
            sx={{ blockSize: { sm: "80%", md: "90%", lg: "90%", xl: "90%" } }}
          >
            <UserJogos
              multigamesSelection={multigamesSelection}
              deleteGame={deleteGame}
              clearAllGamesSelection={clearAllGamesSelection}
            ></UserJogos>
            <material.Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-around",
                border: "solid 1px gray",
                marginBlockStart: "3px",
              }}
            >
              <themeJsx.TitleUsers
                align="left"
                paragraph
                variant="h3"
                sx={{ paddingBlockStart: "16px", fontSize: "18px" }}
              >
                Total: R$ {(multigamesSelection.length * GamePrice).toFixed(2)}
              </themeJsx.TitleUsers>
              <themeJsx.TitleUsers sx={{ fontSize: "18px" }}>
                N &#186; jogos: {multigamesSelection.length}
              </themeJsx.TitleUsers>
            </material.Box>
          </material.Grid>

          <material.Grid item md={3} xs={4}>
            <Dateshandler/>
            <themeJsx.TitleUsers component="h3" align="center">
              Como Jogar:
            </themeJsx.TitleUsers>
            <material.List component={"ol"}>
              {orientacoes.map((item) => {
                return (
                  <material.ListItem component={"li"} key={uniqid()}>
                    <material.Typography component={"p"}>
                      {item}
                    </material.Typography>
                  </material.ListItem>
                );
              })}
            </material.List>
          </material.Grid>
        </material.Grid>
      )}

      {user && media && (
        <material.Grid
          container
          columnSpacing={1}
          columns={{ md: 12, xs: 4 }}
          sx={{
            padding: "12px",
            blockSize: "87%",
            flexDirection: "row",
          }}
        >
          <material.Grid item md={3} xs={4}>
          <Dateshandler/>
            <themeJsx.TitleUsers
              component={"h3"}
              align="center"
              sx={{ textDecoration: "underline", fontWeight: "500" }}
            >
              Como Jogar:
            </themeJsx.TitleUsers>
            <material.List component={"ol"}>
              {orientacoes.map((item) => {
                return (
                  <material.ListItem component={"li"} key={uniqid()}>
                    <material.Typography component={"p"}>
                      {item}
                    </material.Typography>
                  </material.ListItem>
                );
              })}
            </material.List>
          </material.Grid>
          <material.Grid item md={5} xs={4} sx={{ blockSize: "110%" }}>
            <LayoutNumbers />
          </material.Grid>
          <material.Grid item md={4} xs={4} sx={{ blockSize: "60%" }}>
            <material.Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-around",
                border: "solid 1px gray",
                marginBlockStart: "3px",
              }}
            >
              <themeJsx.TitleUsers
                align="left"
                paragraph
                variant="h3"
                sx={{ paddingBlockStart: "16px", fontSize: "18px" }}
              >
                Total: R$ {(multigamesSelection.length * GamePrice).toFixed(2)}
              </themeJsx.TitleUsers>
              <themeJsx.TitleUsers sx={{ fontSize: "18px" }}>
                N &#186; jogos: {multigamesSelection.length}
              </themeJsx.TitleUsers>
            </material.Box>
            <UserJogos
              multigamesSelection={multigamesSelection}
              deleteGame={deleteGame}
              clearAllGamesSelection={clearAllGamesSelection}
            ></UserJogos>
          </material.Grid>
        </material.Grid>
      )}
      {!user && <reactRouterDom.Navigate to="/"></reactRouterDom.Navigate>}
    </react.Fragment>
  );
}
