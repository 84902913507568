import { ModalUser } from "../../../theme";
import ClearIcon from "@mui/icons-material/Clear";
import { theme } from "../../../theme.jsx";
import { Typography } from "@mui/material";

export default function InvalidDateGame({ status, changeStatus,inicio,fim }) {

  

  return (
    <ModalUser hideBackdrop={false} disableAutoFocus={true} open={status}>
      <section
        style={{
          blockSize: "100%",

          backgroundColor: theme.palette.laranja.main,
          position: "relative",
        }}
      >
        <ClearIcon
          sx={{
            position: "absolute",
            insetBlockStart: "5%",
            insetInlineEnd: "5%",
          }}
          onClick={() => changeStatus(false)}
        />
        <Typography
          component={"p"}
          sx={{
            color: "white",
            display: "flex",
            blockSize: "100%",
            textAlign: "center",
            alignItems: "center",
            fontSize:'2.0rem',
            justifyContent: 'center'
          }}
        >
          Data válida para novos jogos:
          <br></br>{`${inicio} à ${fim}`}
        </Typography>
      </section>
    </ModalUser>
  );
}
