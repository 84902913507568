import { useEffect, useState } from "react";
import * as auth from "../firebase/auth";
import useAuthContext from "./useAuthContext";

export default function useSingUp() {
  const [error, setError] = useState(null);
  const [isPending, setIsPending] = useState(false);
  const [unmounted, setunmounted] = useState(false);
  const { dispatch } = useAuthContext();

  async function singup(email, password, displayName) {
    setError(null);
    setIsPending(true);
    try {
      const response = await auth.createUserWithEmailAndPassword(
        auth.projetcAuth,
        email,
        password
      );

      await auth.updateProfile(auth.projetcAuth.currentUser, {
        displayName,
      });

      dispatch({ type: "SINGUP", payload: response.user });

      if (!response) {
        throw new Error("problem with singup");
      }

      if (!unmounted) {
        setError(null);
        setIsPending(false);
      }
    } catch (error) {
      setError(error.message);
      if (!unmounted) {
        setError(null);
        setIsPending(false);
      }
    }
  }

  async function singupWithGoogle() {
    try {
      const response = await auth.signInWithPopup(auth.projetcAuth, auth.provider);
      auth.provider.credentialFromResult(response);

      //credential.accessToken;
      if (!unmounted) {
        setError(null);
        setIsPending(false);
      }
    } catch (error) {
      setError(error.code)
      auth.provider.credentialFromError(error);
      if (!unmounted) {
        setError(null);
        setIsPending(false);
      }
    }
  }

  useEffect(() => setunmounted(true), []);

  return { setError, error, isPending, singup, singupWithGoogle };
}
