import { AppBar, Container, Button, ListItem } from "@mui/material";
import logo from "../../assets/logo.jpeg";
import Navbar from "./Navbar.jsx";
import useAuthContext from "../../hooks/useAuthContext";
import useLogout from "../../hooks/useLogout";
import { useFirestore } from "../../hooks/useFirestore.jsx";
import { theme } from "../../theme.jsx";
import { StyledLink } from "../../theme.jsx";

const options = [
  "Inicio",
  "Meus Dados",
  "Meus Resultados",
  "Ganhador/Dia",
  "Resultado Geral",
];

function renderLink(name) {
  switch (name) {
    case "Inicio":
      return "/user";
    case "Logar":
      return "/login";
    case "Cadastrar":
      return "/singup";
    case "Ganhador/Dia":
      return "/win_day";
    case "Meus Dados":
      return "/cadastro";
      case "Meus Resultados":
        return "/meus_resultados";
    case "Resultado Geral":
      return "/resultado_geral";
    default:
      return "/";
  }
}

function SubMenu() {
  const { logout } = useLogout();
  const { requesCadastroFromDB } = useFirestore();
  const { user, isAdmin, winner } = useAuthContext();
  return (
    <Container
      component="ul"
      sx={{
        color: "white",
        display: { xs: "none", sm: "none", md: "flex", lg: "flex", xl: "flex" },
        justifyContent: "space-evenly",
        backgroundColor: theme.palette.secundary.main,
        inlineSize: "100%",
        maxWidth: { xl: "100%", lg: "100%" },
        "&.MuiContainer-root": {
          paddingInlineStart: "2%",
        },

        "& > li": {
          margin: "0",
          fontSize: "13px",
          textAlign: "center",
          blockSize: "auto",
          width: "auto",
          padding: "5px",
          "& > a": {
            fontSize: "inherit",
          },
        },
        "& > li:hover": {
          backgroundColor: theme.palette.laranja.main,
          borderRadius: "5px",
        },
      }}
    >
      {user &&
        options.map((page) => (
           <ListItem
            key={page}
            sx={{
              my: 2,
              color: "white",
              display: "block",
              fontSize: "0.8rem",
              marginBlockEnd: "0",
            }}
          >
            {
              <StyledLink
                to={renderLink(page)}
                onClick={() => {
                  if (page === "Meus Dados") {
                    requesCadastroFromDB(user.uid);
                  } else if (page === "Sair") {
                    logout();
                  }
                }}
              >
                {page}
              </StyledLink>
            }
          </ListItem>
        ))}

      {isAdmin && user && (
        <Button sx={{ my: 2, color: "white", display: "block" }} component="li">
          <StyledLink to="admin">Gerenciar</StyledLink>
        </Button>
      )}
      {winner && user && (
        <Button sx={{ my: 2, color: "white", display: "block" }} component="li">
          <StyledLink to="vencedor">Vencedor</StyledLink>
        </Button>
      )}
    </Container>
  );
}

export default function Header() {
  return (
    <AppBar position="static" sx={{ blockSize: { sm: "10%", md: "auto" } }}>
      <Container
        component={"img"}
        src={logo}
        sx={{
          display: { md: "block", sm: "none", xs: "none" },
          inlineSize: "160px",
          position: "absolute",
          "&": {
            paddingLeft: "0px",
            paddingRight: "0px",
          },
        }}
      ></Container>
      <Navbar></Navbar>
      <SubMenu></SubMenu>
    </AppBar>
  );
}
