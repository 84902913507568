import { BrowserRouter, Routes, Route } from "react-router-dom";
import RoutesComponent from "./Componentes/UI/RoutesComponent";
import AuthContextProvider from "./Context/ContextProvider";
import AdminContextProvider from "./Context/AdminPainelProvider";
import Home from "./Pages/Home/Home";
import Login from "./Pages/Login";
import Singup from "./Pages/Singup";
import Error from "./Pages/Error";
import React from "react";
import User from "./Pages/user/Jogo/User";
import DadosCadastroUser from "./Pages/user/Cadastro/DadosCadastroUser.jsx";
import Resultado from "./Pages/Resultado/Resultado.jsx";
import ResultadoGeral from "./Pages/ResultadoGeral/ResultadoGeral.jsx";
import Admin from "./Pages/Admin/Admin.jsx";
import Vencedor from "./Pages/Vencedor/Vencedor.jsx";
import { theme } from "./theme.jsx";
import { ThemeProvider } from "@mui/material/styles";
import ResultadoDia from "./Pages/ResultadoDia/ResultadoDia";

function App() {
  return (
    <BrowserRouter>
      <AuthContextProvider>
        <ThemeProvider theme={theme}>
          <Routes>
            <Route element={<RoutesComponent></RoutesComponent>}>
              <Route index path="/" element={<Home></Home>} />
              <Route path="singup" element={<Singup></Singup>} />
              <Route path="login" element={<Login></Login>} />
              <Route path="user" element={<User></User>} />
              <Route
                path="cadastro"
                element={<DadosCadastroUser></DadosCadastroUser>}
              />
              <Route path="win_day" element={<ResultadoDia></ResultadoDia>} />
              <Route path="/*" element={<Error></Error>} />
              <Route path="meus_resultados" element={<Resultado />} />
              <Route path="resultado_geral" element={<ResultadoGeral />} />
              <Route
                path="admin"
                element={
                  <AdminContextProvider>
                    <Admin />
                  </AdminContextProvider>
                }
              />
              <Route path="vencedor" element={<Vencedor />} />
            </Route>
          </Routes>
        </ThemeProvider>
      </AuthContextProvider>
    </BrowserRouter>
  );
}

export default App;
