import react from "react";
import Cadastro from "./Cadastro.jsx";
import { ModalUser } from "../../../theme.jsx";
import { projetcFirestore, doc, getDoc } from "../../../firebase/firestore";
import useAuthContext from "../../../hooks/useAuthContext.jsx";

export default function UserCadastro({ uid }) {
  const { disableModalAfterCadastro } = useAuthContext();
  const [loadForm, setloadForm] = react.useState(false);

  react.useEffect(() => {
    (async () => {
      try {
        let docRef = doc(projetcFirestore, `usuarios/${uid}`);
        let docSnap = await getDoc(docRef);
        if (docSnap.exists()){
          setloadForm(false)
        }else{
          setloadForm(true)
        }
        return Promise.resolve();
      } catch (error) {
        return Promise.reject();
      }
    })();
  }, [uid]);

 

  return (
    <>
    {disableModalAfterCadastro && <ModalUser hideBackdrop={false} disableAutoFocus={true} open={loadForm}>
      <section style={{ blockSize: "100%" }}>
        <Cadastro
          setmodal={setloadForm}
          uid={uid}
          editApelido={false}
        ></Cadastro>
      </section>
    </ModalUser>}
    </>
  );
}
