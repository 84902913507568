import {
  getFirestore,
  collection,
  Timestamp,
  query,
  where,
  onSnapshot,
  doc,
  getDoc,
  getDocs,
  collectionGroup,
   
  
  
} from "firebase/firestore";
import { app } from "./config";

const projetcFirestore = getFirestore(app);
export {
  projetcFirestore,
  collection,
  Timestamp,
  query,
  where,
  onSnapshot,
  doc,
  getDoc,
  getDocs,
  collectionGroup,
  
};
