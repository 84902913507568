import { useContext } from "react";
import { AdminContext } from "../Context/AdminPainelProvider";


export default function useAdminContext() {

    const context = useContext(AdminContext)

    if(!context){
        console.log('useAdminContext must be used inside a adminContextProvider ')
    }

    return context
}