import {
  Box,
  Toolbar,
  IconButton,
  Typography,
  Menu,
  Button,
  MenuItem,
} from "@mui/material";
import useAuthContext from "../../hooks/useAuthContext";
import useLogout from "../../hooks/useLogout";
import { useFirestore } from "../../hooks/useFirestore.jsx";
import { useState } from "react";

import { StyledLink } from "../../theme.jsx";

const pages = ["Logar", "Cadastrar"];
const options = [
  "Inicio",
  "Meus Dados",
  "Meus Resultados",
  "Ganhador/Dia",
  "Resultado Geral",
  "Sair",
];

export default function Navbar() {
  const [anchorElNav, setAnchorElNav] = useState(null);
  const { user, isAdmin, winner } = useAuthContext();
  const { requesCadastroFromDB } = useFirestore();
  const { logout } = useLogout();
  
  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  function renderLink(name) {
    switch (name) {
      case "Inicio":
        return "/user";
      case "Logar":
        return "/login";
      case "Cadastrar":
        return "/singup";
      case "Ganhador/Dia":
        return "/win_day";
      case "Meus Dados":
        return "/cadastro";
      case "Meus Resultados":
        return "/meus_resultados";
      case "Resultado Geral":
        return "/resultado_geral";
      case "Sair":
        return "/";
      default:
        return "/";
    }
  }

  return (
    <Toolbar
      disableGutters
      component="menu"
      sx={{
        "&.MuiToolbar-root": {
          minBlockSize: "70px",
        },
      }}
    >
      <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
        <IconButton
          sx={{ blockSize: "45px" }}
          size="madium"
          aria-label="account of current user"
          aria-controls="menu-appbar"
          aria-haspopup="true"
          onClick={handleOpenNavMenu}
          color="textLight"
        >
          <Typography component='h2' gutterBottom={true}>OPÇÕES</Typography>
        </IconButton>
        <Menu
          id="menu-appbar"
          anchorEl={anchorElNav}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          keepMounted
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          open={Boolean(anchorElNav)}
          onClose={handleCloseNavMenu}
          sx={{
            display: { xs: "block", md: "none" },
          }}
        >
          {!user &&
            pages.map((page) => (
              <MenuItem key={page} onClick={handleCloseNavMenu}>
                {!user && (
                  <Typography textAlign="center" variant="h3">
                    <StyledLink to={renderLink(page)}>{page}</StyledLink>
                  </Typography>
                )}
              </MenuItem>
            ))}
          <MenuItem onClick={handleCloseNavMenu}>
            {winner && user && (
              <Typography>
                <StyledLink to="vencedor">Vencedor</StyledLink>
              </Typography>
            )}
          </MenuItem>
          {user &&
            options.map((page) => (
              <MenuItem key={page} onClick={handleCloseNavMenu}>
                {user && (
                  <Typography textAlign="center">
                    <StyledLink
                      to={renderLink(page)}
                      onClick={() => {
                        if (page === "Meus Dados") {
                          requesCadastroFromDB(user.uid);
                        } else if (page === "Sair") {
                          logout();
                        }
                      }}
                    >
                      {page}
                    </StyledLink>
                  </Typography>
                )}
              </MenuItem>
            ))}

          <MenuItem onClick={handleCloseNavMenu}>
            {isAdmin && (
              <Typography>
                <StyledLink to="admin">Gerenciar</StyledLink>
              </Typography>
            )}
          </MenuItem>
        </Menu>
      </Box>

      {/* codigo pode ser descartado, mostrar pages na header: login e cadastro */}

      <Box
        component="ul"
        sx={{
          flexGrow: 1,
          display: { xs: "none", md: "flex" },
          marginInlineEnd: "30px",
          justifyContent: "flex-end",
        }}
      >
        {user && (
          <Button color="textLight" onClick={() => logout()}>
            <StyledLink to="/">Sair</StyledLink>
          </Button>
        )}
      </Box>
    </Toolbar>
  );
}
