import { createContext, useEffect, useReducer } from "react";
import { projetcAuth, onAuthStateChanged } from "../firebase/auth";

export const AuthContext = createContext();

function authReducer(state, action) {
  switch (action.type) {
    case "SINGUP":
      return { ...state, user: action.payload };
    case "LOGIN":
      return { ...state, user: action.payload };
    case "LOGOUT":
      return { ...state, user: null };
    case "cadastro":
      return { ...state, userCadastro: action.payload };
    case "isAdmin":
      return { ...state, isAdmin: action.payload };
    case "winner":
      return { ...state, winner: action.payload };
    case "disableModalAfterCadastro":
      return { ...state, disableModalAfterCadastro: action.payload };
    case "AUTH_IS_READY":
      return { ...state, user: action.payload, autentication: true };
    default:
      return state;
  }
}



export default function AuthContextProvider({ children }) {
  const [state, dispatch] = useReducer(authReducer, {
    user: null,
    autentication: false,
    userCadastro: null,
    isAdmin: false,  
    winner: false,
    disableModalAfterCadastro: true
  });
  useEffect(() => {
    onAuthStateChanged(projetcAuth, (user) => {
      dispatch({ type: "AUTH_IS_READY", payload: user });
     
    });
  }, []);
  return (
    <AuthContext.Provider value={{ ...state, dispatch }}>
      {children}
    </AuthContext.Provider>
  );
}
