import * as material from '@mui/material';
import * as firestoreJs from '../../../firebase/firestore.js';
import useAuthContext from '../../../hooks/useAuthContext.jsx';
import * as reactRouterDom from 'react-router-dom';
import Cadastro from './Cadastro.jsx';
import react from 'react';

export default function DadosCadastroUser() {
  const {user, userCadastro, dispatch} = useAuthContext();
  const [isPending, setIsPending] = react.useState(true);

  const setmodal = function(valor) {
    return () => valor;
  };

  react.useEffect(() => {
    (async () => {
      const docRef = firestoreJs.doc(
          firestoreJs.projetcFirestore,
          `usuarios/${user.uid}`,
      );
      const data = await firestoreJs.getDoc(docRef);
      dispatch({type: 'cadastro', payload: data.data()});
    })();
    setIsPending(false);
  }, [dispatch, user.uid]);

  return (
    <react.Fragment>
      {isPending && !userCadastro && (
        <material.CircularProgress color="secondary" />
      )}
      {user ? (
        <section style={{blockSize: '85%', textAlign: 'center'}}>
          <h2>Meus Dados</h2>
          <Cadastro
            setmodal={setmodal}
            userCadastro={userCadastro}
            editApelido={true}
          ></Cadastro>
        </section>
      ) : (
        <reactRouterDom.Navigate to="/" replace={true} />
      )}
    </react.Fragment>
  );
}
