import useAdminContext from "../../hooks/useAdminContext";
import React from "react";
import { useEffect } from "react";
import ListOfPayments from "../../Componentes/UI/ListOfPayments";
import * as fire from "../../firebase/firestore";
import * as materialUi from "@mui/material";
import * as date from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import * as func2 from "firebase/functions";
import moment from "moment";
import { app } from "../../firebase/config.js";

export default function Admin() {
  const functions = func2.getFunctions(app,"southamerica-east1");
  const { dispatch, adminPainel } = useAdminContext();
  const locale = "pt";
  const message = {
    pt: {
      nextMonth: "Proximo mês",
      previousMonth: "Mês anterior",
    },
  };
  const [inicio, setinicio] = React.useState();
  const [fim, setfim] = React.useState();
  const [playgame, setplayGame] = React.useState();
  
  useEffect(() => {
    if (adminPainel === null) {
      (async () => {
        let aux = [];
        let allGamesDocsRef = fire.collection(
          fire.projetcFirestore,
          "allGames"
        );
        let query = fire.query(
          allGamesDocsRef,
          fire.where("pagamento", "==", false)
        );
        let docSnaps = await fire.getDocs(query);
        docSnaps.forEach((val) => {
          let temp = {
            uid: val.get("uid"),
            pagamento: val.get("pagamento"),
            apelido: val.get("apelido"),
            jogos: Object.values(val.get('jogo')).length
          };

          aux.push(temp);
        });
        aux.sort((a, b) => {
          if (a.apelido < b.apelido) {
            return -1;
          }
          if (a.apelido > b.apelido) {
            return 1;
          }
          return 0;
        });
        dispatch({ type: "adminPainel", payload: aux });
      })();
    }
  }, [adminPainel, dispatch]);

  function handleBegging(newValue) {
    setinicio(newValue);
  }
  function handleEnd(newValue) {
    setfim(newValue);
  }
  function play(newValue) {
    setplayGame(newValue);
  }

  let DivPayment = materialUi.styled(materialUi.Box)({
    position: "relative",
    insetBlockStart: "3%",
    margin: "auto",
    inlineSize: "60%",
    blockSize: "70%"
  });

  let ResetButton = materialUi.styled(materialUi.Button)({
    backgroundColor: "red",
  });
  

  return (
    <section
      style={{ display: "flex", flexDirection: "column", blockSize: "90%" }}
    >
      <materialUi.Container
        component="section"
        sx={{
          paddingBlock: "32px",
          display: "flex",
          justifyContent: "space-around",
          blockSize: {
            
            md:'10%',
            lg:'10%',
            xl:'10%',
          },
          rowGap: "24px",
          alignItems: "center",
          flexWrap: "nowrap",
          flexDirection: {
            xs: "column",
            sm: "row",
            md: "row",
          },
        }}
      >
        <date.LocalizationProvider
          adapterLocale={locale}
          message={message}
          dateAdapter={AdapterMoment}
        >
          <date.MobileDatePicker
            label="Aceitar jogos - Inicio"
            inputFormat="DD/MM/YYYY"
            value={inicio}
            onChange={handleBegging}
            renderInput={(params) => <materialUi.TextField {...params} />}
          />
          <date.MobileDatePicker
            label="Aceitar jogos - Fim"
            inputFormat="DD/MM/YYYY"
            value={fim}
            onChange={handleEnd}
            renderInput={(params) => <materialUi.TextField {...params} />}
          />
          <date.MobileDatePicker
            label="Inicar Sorteios"
            inputFormat="DD/MM/YYYY"
            value={playgame}
            onChange={play}
            renderInput={(params) => <materialUi.TextField {...params} />}
          />
        </date.LocalizationProvider>
      </materialUi.Container>
      <materialUi.Container
        component="section"
        sx={{
         
          blockSize: "7%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-around",
          inlineSize: {
            sm:"90%",
            md:'40%',
            lg:'40%',
            xl:'40%',

          },
        }}
      >
        <materialUi.Button
          sx={{ blockSize: "80%", inlineSize: "100%", marginInlineEnd: "8px" }}
          variant="contained"
          onClick={() => {
            func2.httpsCallable(
              functions,
              "salvarDatas"
            )({
              inicio: moment(inicio).format().valueOf(),
              fim: moment(fim).format().valueOf(),
              playgame: moment(playgame).format().valueOf(),
            });
          }}
        >
          Salvar Datas
        </materialUi.Button>
        <ResetButton
          sx={{ blockSize: "80%", inlineSize: "100%" }}
          variant="contained"
          onClick={() => {
            func2.httpsCallable(functions, "resetGame")();
          }}
        >
          Resetar Game
        </ResetButton>
      </materialUi.Container>
      {!!adminPainel && (
        <DivPayment  component="section">
          <ListOfPayments arrayGames={adminPainel} />
        </DivPayment>
      )}
    </section>
  );
}
