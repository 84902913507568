import { useContext } from "react";
import { AuthContext } from "../Context/ContextProvider";

export default function useAuthContext() {

    const context = useContext(AuthContext)

    if(!context){
        console.log('useAuthcontext must be used inside a authContextProvider ')
    }

    return context
}