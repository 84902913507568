
import {
  projetcFirestore,
  collection,
  where,
  getDocs,
  query,
} from "../firebase/firestore";
export default async function getAllGameDataHook() {
  const q = query(
    collection(projetcFirestore, "allGames"),
    where("pagamento", "==", true)
  );
  const querySnapshot = await getDocs(q);

  let auxiliar = [];
  querySnapshot.forEach((queryDocSnap) => auxiliar.push(queryDocSnap.data()));

  return auxiliar
}
