import react from "react";
import ListNumberShowAcertos from "../../Componentes/UI/ListNumberShowAcertos";
import uniqid from "uniqid";
import * as material from "@mui/material";
import * as reactRouterDom from "react-router-dom";
import * as theme from "../../theme";
import useAuthContext from "../../hooks/useAuthContext";
import * as firestore from "../../firebase/firestore.js";

export default function ResultadoDia() {
  const [resultadoDay, setresultadoDay] = react.useState(null);
  const [sorteioDay, setsorteioDay] = react.useState(["-", "-", "-", "-", "-"]);
  const [isPendingData, setisPendingData] = react.useState(true);
  const [pontos, setPontos] = react.useState(null);
  const [data, setdata] = react.useState(null);
  const [validDate,setValidDate] = react.useState(false)
  const { user } = useAuthContext();

  react.useEffect(() => {
    let docRefSorteio = firestore.doc(
      firestore.projetcFirestore,
      "sorteios/resultadoQuina"
    );

    (async () => {
      const vitoriosos = await firestore.getDocs(
        firestore.collection(firestore.projetcFirestore, "vitoriosos")
      );
      
      const querySnapshotSoteio = await firestore.getDoc(docRefSorteio);
     
   if (querySnapshotSoteio.exists() && querySnapshotSoteio.data().numero){

     setsorteioDay(querySnapshotSoteio.data().numero);
     setdata(querySnapshotSoteio.data().data.seconds);

     vitoriosos.forEach((doc) => {
       let auxiliar = Object.values(doc.data());
       if (auxiliar.length > 0) {
         setresultadoDay(auxiliar);
         setPontos(doc.data()["0"].ponto);
         setValidDate(true)
        }
      });
    }
    setisPendingData(false);
    })();
  }, []);
  return (
    <>
      {!user && <reactRouterDom.Navigate to="/"></reactRouterDom.Navigate>}
      {isPendingData && <material.LinearProgress color="laranja" />}

      <material.Typography
        component="section"
        sx={{
          "& > div:nth-of-type(even)": {
            backgroundColor: "white",
          },
          "& > div:nth-of-type(odd)": {
            backgroundColor: "gray",
          },
          paddingBlockEnd: "5px",
        }}
      >
        {isPendingData === true ? (
          <h3 style={{ textAlign: "center", paddingBlockStart: "5%" }}>
            {" "}
            Carregando Informações
          </h3>
        ) : (
          <h3 style={{ textAlign: "center", paddingBlockEnd: "1%" }}>
            Sorteio
          </h3>
        )}

        <material.Typography
          component="ul"
          sx={{
            display: "flex",
            flexDirection: "row",
            inlineSize: "100%",
            justifyContent: "space-around",
            paddingInline: "60px",
          }}
        >
          {validDate && sorteioDay.map((val) => {
            return (
              <material.Typography
                component="li"
                key={uniqid()}
                sx={{
                  display: "inline",
                  borderRadius: "100%",
                  padding: "6px",
                  paddingBlockStart: "4px",
                  backgroundColor: theme.theme.palette.laranja.main,
                  marginInline: "2px",
                  blockSize: "30px",
                }}
              >
                {val}
              </material.Typography>
            );
          })}
        </material.Typography>

        {pontos && (
          <h3 style={{ textAlign: "center", paddingBlock: "2%" }}>
            {new Date(data * 1000).toLocaleDateString("pt-br", {
              month: "long",
              day: "numeric",
            })}{" "}
            <br></br>
            {`${resultadoDay.length} Ganhadores com ${pontos} PONTO(S)`}
          </h3>
        )}
        {!Boolean(resultadoDay) && (
          <material.Typography componente="h3" sx={{ textAlign: "center" }}>
            Sem vencedores ou fora do Prazo de sorteio
          </material.Typography>
        )}
        {Boolean(resultadoDay) &&
          resultadoDay.map((win) => {
            return (
              <material.Box
                key={uniqid()}
                component ='ul'
                sx={{
                  display: "flex",
                  inlineSize: "100%",
                  blockSize:'50px',
                  paddingBlock: "1%",
                  [theme.theme.breakpoints.down("md")]: {
                    justifyContent: "space-evenly",
                  },
                  paddingInline: "3px",
                  justifyContent: "space-around",
                  alignItems: "center",
                }}
              >
                <material.Typography
                  component="p"
                  sx={{
                    display: "inline",
                    fontWeight: 500,
                    fontSize: "1rem",
                    inlineSize: "20%",
                  }}
                >
                  {win.apelido}
                </material.Typography>
                <material.Typography
                  component="li"
                  sx={{
                    display: "inline-flex",
                    flexDirection: "row",
                    justifyContent: "space-around",
                    flexWrap: "nowrap",
                    rowGap: "6px",
                    inlineSize: "75%",
                    blockSize:'100%'
                  }}
                >
                  {typeof win.game === "string"
                    ? []
                    : win.game.map((game) => {
                        return (
                          
                          <ListNumberShowAcertos
                            paddStyle={'2px 1px 1px 4px'}
                            key={uniqid()}
                            numero={game.value}
                            selecao={game.selection}
                            back={`${theme.theme.palette.laranja.main}`}
                            backselect={theme.theme.palette.primary.main}
                          ></ListNumberShowAcertos>
                          
                        );
                      })}
                </material.Typography>
              </material.Box>
            );
          })}
      </material.Typography>
    </>
  );
}
