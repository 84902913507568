import { useEffect, useState } from "react";
import {
  projetcAuth,
  signInWithEmailAndPassword,
  setPersistence,
  browserSessionPersistence
   
  
} from "../firebase/auth";
import * as firestoreMod from "../firebase/firestore"
import useAuthContext from "./useAuthContext";
import { useFirestore } from "./useFirestore.jsx";

export default function useLogin() {
  
  const [error, setError] = useState(null);
  const [isPending, setIsPending] = useState(false);
  const [unmounted, setunmounted] = useState(false);
  const { dispatch } = useAuthContext();
  const { requesCadastroFromDB } = useFirestore();

  async function checkIfHasWinner(){
    
    let ref = firestoreMod.doc(firestoreMod.projetcFirestore, "campeao/jogos");
    return await firestoreMod.getDoc(ref)
    
 
}

  async function login(email, password) {
    setError(null);
    setIsPending(true);
    let response = null;
    try {
      setPersistence(projetcAuth,browserSessionPersistence).then(
      async ()=>{
        response = await signInWithEmailAndPassword(projetcAuth,email,password)
      dispatch({ type: "LOGIN", payload: response.user });
      requesCadastroFromDB(response.user.uid)
      let isAdmin = await response.user.getIdTokenResult()
      dispatch({ type: "isAdmin", payload: isAdmin.claims.admin });

      checkIfHasWinner().then(val =>{
        dispatch({type: 'winner', payload: val.data().existeCampeao})
      })
        
      if (!response) {
        throw new Error("problem with login");
      }

      if (!unmounted) {
        setError(null);
        setIsPending(false);
      }
    
    }).catch((error)=>setError(error));

      
      
    } catch (error) {
      setError(error.code);

      if (!unmounted) {
        setError(null);
        setIsPending(false);
      }
    }
    return response
  }
  

  useEffect(() => setunmounted(true), []);

  return {  isPending, login, error };
}
