import * as React from "react";
import * as material from "@mui/material";
import uniqid from "uniqid";
import { app } from "../../firebase/config.js";
import * as func from "firebase/functions";

export default function ListOfPayments({ arrayGames }) {
  const functions = func.getFunctions(app,"southamerica-east1");
  const PRICEGAME = 20;
  function ItemListPayment({ userInfo }) {
    const [ispayed, setispayed] = React.useState(userInfo.pagamento);
    return (
      <React.Fragment>
      <material.ListItem >
        <material.ListItemText id={userInfo.uid} primary={userInfo.apelido} secondary={`${userInfo.jogos} - R$ ${userInfo.jogos * PRICEGAME}`}/>
        <material.Switch
          edge="end"
          onClick={() =>
            func.httpsCallable(
              functions,
              "paymentStatus"
            )({ pagamento: !ispayed, uid: userInfo.uid })
          }
          onChange={(event) => {
            setispayed(event.target.checked);
          }}
          checked={ispayed}
        />
      </material.ListItem>
      <material.Divider></material.Divider>
      </React.Fragment>
    )
  }

  return (
    <material.List
      sx={{
        inlineSize: "100%",
        bgcolor: "background.paper",
        blockSize:'100%'
      }}
      subheader={<material.ListSubheader>Pagamento</material.ListSubheader>}
    >
      {arrayGames.map((user) => {
        return <ItemListPayment key={uniqid()} userInfo={user}/>
      })}
    </material.List>
  )
}
