import { initializeApp } from "firebase/app";
import { initializeAppCheck, ReCaptchaV3Provider, getToken } from "firebase/app-check";

const firebaseConfig = {
  apiKey: "AIzaSyBNe-XGkvOx7rW_-7PTowzIt4J8CyEO1nk",
  authDomain: "friends-7079a.firebaseapp.com",
  projectId: "friends-7079a",
  storageBucket: "friends-7079a.appspot.com",
  messagingSenderId: "737297562336",
  appId: "1:737297562336:web:3cb9b682d00749097eee85"
};

const app = initializeApp(firebaseConfig)
if (process.env.NODE_ENV === 'development') {
  window.self.FIREBASE_APPCHECK_DEBUG_TOKEN = true;
}


const appCheck = initializeAppCheck(app, {
  provider: new ReCaptchaV3Provider('6LfG-7MkAAAAAEY_l7w0Lp76DGv3HLqK1ni0hU3m'),
  isTokenAutoRefreshEnabled: true
});
getToken(appCheck)
  .then(() => {
    console.log('success')
  })
  .catch((error) => {
    console.log(error.message)
  })




export {app}

