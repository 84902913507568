import useAuthContext from "./useAuthContext";
import { projetcFirestore, doc, getDoc } from "../firebase/firestore";

export function useFirestore() {
  const {  dispatch } = useAuthContext();

  async function requesCadastroFromDB(userInf) {
    var cadastro = {};
    var docRef = doc(projetcFirestore, `usuarios/${userInf}`);
    var docSnap = await getDoc(docRef);
    if (docSnap.exists) {
      cadastro = docSnap.data();
    }

    dispatch({ type: "cadastro", payload: cadastro });
  }

  return { requesCadastroFromDB };
}
