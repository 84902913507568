import  react from "react";
import * as material from "@mui/material";
import * as reactRouterDom from "react-router-dom";
import useSingUp from "../hooks/useSingup";
import useAuthContext from "../hooks/useAuthContext";
import GoogleIcon from "@mui/icons-material/Google";

const FormLogin = material.styled("form")(({ theme }) => ({
  blockSize: "100%",
  marginInline: "auto",

  [theme.breakpoints.down("md")]: {
    inlineSize: "55%",
  },
  [theme.breakpoints.up("md")]: {
    inlineSize: "30%",
  },
}));

export default function Singup() {
  const [email, setemail] = react.useState("");
  const [password, setpassword] = react.useState("");
  const [displayName, setdisplayName] = react.useState("");
  const { singup, singupWithGoogle, error, setError } = useSingUp();
  const { user } = useAuthContext();
  const location = reactRouterDom.useLocation();
  const fields = [
    { name: "seu nome", type: "text", value: displayName, func: setdisplayName },
    { name: "cadastre um e-mail", type: "email", value: email, func: setemail },
    { name: "criar senha", type: "password", value: password, func: setpassword },
  ];

  // onclick esta comentado 
  function singupWithGoogleHandler(event) {
    event.preventDefault();
    singupWithGoogle();
  }

  function formHandler(event) {
    event.preventDefault();
    singup(email, password, displayName);
  }

  setTimeout(() => {
    setError(null);
  }, 1500);

  return (
    <section style={{ blockSize: "60%", paddingBlockStart: "15%" }}>
      {!user && (
        <material.Container sx={{ blockSize: "100%" }}>
          <FormLogin>
            <material.Stack
              spacing={2}
              sx={{ blockSize: "100%", justifyContent: "space-evenly" }}
            >
              {fields.map((field, index) => {
                return (
                  <material.TextField
                    key={index}
                    autoComplete="false"
                    label={field.name}
                    type={field.type}
                    required
                    helperText={password.length < 6 && field.type === 'password' ? "crie uma senha difícil com mín 6 caracteres": ""}
                    size="small"
                    value={field.value}
                    onChange={(e) => field.func(e.target.value)}
                  />
                );
              })}

              {error && (
                <material.Typography paragraph color="error">
                  {error === "Firebase: Error (auth/email-already-in-use)."
                    ? "e-mail ja cadastrado"
                    : "problema com cadastro, refaça email e/ou senha"}
                </material.Typography>
              )}
              <material.Button
                variant="contained"
                color="primary"
                sx={{inlineSize:'100%'}}
                onClick={(e) => formHandler(e)}
              >
                {" "}
                Cadastrar
              </material.Button>
              <material.Button
                startIcon={<GoogleIcon />}
                variant="contained"
                color="primary"
                onClick={(e) => singupWithGoogleHandler(e)}
              >
                Cadastrar com google
              </material.Button>
            </material.Stack>
          </FormLogin>
        </material.Container>
      )}
      {user && (
        <reactRouterDom.Navigate
          to="/user"
          replace={true}
          state={{ from: location }}
        ></reactRouterDom.Navigate>
      )}
    </section>
  );
}
