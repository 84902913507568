import React from "react";
import { Outlet } from "react-router-dom";
import Header from "./Header";
import useAuthContext from "../../hooks/useAuthContext";

export default function RoutesComponent() {
  const { autentication } = useAuthContext();
  return (
    <React.Fragment>
      {autentication && (
        <>
          <Header></Header>
          <Outlet></Outlet>
        </>
      )}
    </React.Fragment>
  );
}
