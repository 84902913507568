import { createContext, useReducer } from "react";
//import * as fire from "../firebase/firestore";

export const AdminContext = createContext();

function adminReducer(state, action) {
  switch (action.type) {
    case "adminPainel":
        
      return { ...state, adminPainel: action.payload };

    default:
      return state;
  }
}

export default function AdminContextProvider({ children }) {
  const [state, dispatch] = useReducer(adminReducer, {
    adminPainel: null,
  });
  return (
    <AdminContext.Provider value={{ ...state, dispatch }}>
      {children}
    </AdminContext.Provider>
  );
}
