import react from 'react';
import * as material from '@mui/material';
import * as functions_1 from 'firebase/functions';
import * as firestore from '../../../firebase/firestore';
import * as themeJsx from '../../../theme.jsx';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import useAuthContext from '../../../hooks/useAuthContext.jsx';
import {app} from '../../../firebase/config.js';
import useDebouce from '../../../hooks/useDebouce.jsx';

function Cadastro(props) {
  const functions = functions_1.getFunctions(app, "southamerica-east1");
  const {uid, userCadastro, editApelido, setmodal} = props;
  const {dispatch} = useAuthContext();
  const deboucedChange = useDebouce(checkApelidoFirestore, 500);


  react.useEffect(() => {
    if (userCadastro) {
      setcadastro(userCadastro);
    }
  }, [userCadastro]);

  const [cadastro, setcadastro] = react.useState({
    telefone: '',
    apelido: '',
    banco: '',
    agencia: '',
    conta: '',
    pix: '',
    uid: uid,
  });

  const [telefoneError, settelefoneError] = react.useState('');
  const [invalidForm, setinvalidForm] = react.useState('');
  const [apelidoHandler, setapelidoHandler] = react.useState('');


  const bancos = [
    {value: 'Banco do Bradesco S.A', label: 'Banco do Bradesco S.A'},
    {value: 'Banco Itaú', label: 'Banco Itaú'},
    {value: 'Caixa Economica Federal', label: 'Caixa Economica Federal'},
    {value: 'Banco Santander', label: 'Banco Santander'},
    {value: 'HSBC Bank Brasil', label: 'HSBC Bank Brasil'},
    {value: 'Banco do Brasil', label: 'Banco do Brasil'},
    {value: 'Sicoob', label: 'Sicoob'},
    {value: 'Nubank', label: 'Nubank'},
  ];

  function telephoneNumberIsValid(number) {
    const value = /^\(?[1-9]{2}\)? ?(?:[2-8]|9[1-9])[0-9]{3}-?[0-9]{4}$/;

    return value.test(Number(number));
  }

  function handleCellphoneInput(event) {
    if (!telephoneNumberIsValid(event.target.value)) {
      settelefoneError('Telefone inválido');
      setcadastro({...cadastro, telefone: event.target.value});
    } else {
      setcadastro({...cadastro, telefone: event.target.value});
      settelefoneError('');
    }
  }

  function validForm(itens) {
    const validator = [];

    Boolean(telefoneError !== 'Telefone inválido') ?
      validator.push(true) :
      validator.push(false);

    for (const item in itens) {
      if (itens[item].trim().length >= 2) {
        validator.push(true);
      } else {
        validator.push(false);
      }
    }

    return validator;
  }

  function verifyUniqueApelido(event) {
    setcadastro({
      ...cadastro,
      apelido: String(event.target.value).toUpperCase(),
    });
    deboucedChange(event.target.value.toUpperCase());
  }

  async function checkApelidoFirestore(apelidoValue) {
    const apelidoExist = firestore.query(
        firestore.collection(firestore.projetcFirestore, 'usuarios'),
        firestore.where('apelido', '==', apelidoValue),
    );
    const qSnap = await firestore.getDocs(apelidoExist);
    if (qSnap.empty) {
      setapelidoHandler(false);
    } else {
      setapelidoHandler(true);
    }
    return Promise.resolve();
  }

  function handleFormCadastro(e) {
    e.preventDefault();

    const sendData = validForm(cadastro);

    if (apelidoHandler) {
      setinvalidForm('Apelido ja selecionado, tente outro');
    } else {
      if (sendData.includes(false)) {
        setinvalidForm('Formulário possui campo inválido ou em branco');
      } else {
        dispatch({type: 'cadastro', payload: cadastro});
        functions_1.httpsCallable(functions, 'userCadastro')(cadastro);
        setmodal(false);
        dispatch({type: 'disableModalAfterCadastro', payload: false});
      }
    }
    setTimeout(() => {
      setinvalidForm('');
    }, 2500);
  }

  return (
    <themeJsx.SectionCadastro >
      <material.Typography
        component={'h1'}
        variant="h8"
        align="center"
        color={themeJsx.theme.palette.textLight.main}
      >
        Informações de Cadastro
      </material.Typography>
      <material.Stack
        spacing={2}
        sx={{
          blockSize: '23%',
          justifyContent: 'space-between',
          paddingBlock: '16px',
        }}
      >
        <material.TextField
          autoComplete="false"
          label="Telefone"
          type="number"
          required
          size="small"
          value={cadastro.telefone}
          color="primary"
          sx={{backgroundColor: 'white'}}
          onChange={(event) => handleCellphoneInput(event)}
          helperText={telefoneError}
          placeholder="xx-xxxxx-xxxx"
        />
        <material.TextField
          error={Boolean(apelidoHandler)}
          autoComplete="false"
          label="Apelido"
          disabled={editApelido}
          helperText={apelidoHandler === true ? 'use outro apelido' : ''}
          type="string"
          required
          size="small"
          value={cadastro.apelido}
          color="primary"
          sx={{backgroundColor: 'white'}}
          onChange={verifyUniqueApelido}
        ></material.TextField>
      </material.Stack>
      <hr />
      <material.Typography
        component={'h4'}
        align="center"
        sx={{paddingBlockStart: '8px'}}
        color={themeJsx.theme.palette.textLight.main}
      >
        Conta para Depósito
      </material.Typography>
      <material.Stack
        spacing={0}
        sx={{
          blockSize: '70%',
          justifyContent: 'space-evenly',
          paddingBlock: '16px',
          position: 'relative',
          insetBlockStart: '-37px',
        }}
      >
        <material.InputLabel
          id="bancoLabel"
          size="small"
          sx={{margin: '4px', color: '#c3c3c3'}}
        >
          Banco
        </material.InputLabel>
        <material.Select
          style={{margin: '0px'}}
          sx={{backgroundColor: 'white'}}
          size="small"
          color="primary"
          labelId="bancoIdoptions"
          id="banco"
          value={!userCadastro ? cadastro.banco : userCadastro.banco}
          label="Banco"
          onChange={(e) => {
            setcadastro({...cadastro, banco: e.target.value});
          }}
        >
          <material.MenuItem value=""></material.MenuItem>
          {bancos.map((bank) => {
            return (
              <material.MenuItem key={bank.value} value={bank.value}>
                {bank.label}
              </material.MenuItem>
            );
          })}
        </material.Select>
        <material.TextField
          autoComplete="false"
          label="Agencia"
          type="number"
          required
          size="small"
          placeholder="Exemplo: 01709"
          value={cadastro.agencia}
          color="primary"
          sx={{backgroundColor: 'white'}}
          helperText={
            cadastro.agencia.length === 0 ?
              '' :
              cadastro.agencia.length < 4 ?
              'somente numeros' :
              ''
          }
          onChange={(e) => {
            setcadastro({...cadastro, agencia: e.target.value});
          }}
        ></material.TextField>
        <material.TextField
          autoComplete="false"
          label="Conta Corrente"
          type="number"
          required
          size="small"
          placeholder="Exemplo: 974855"
          value={cadastro.conta}
          color="primary"
          sx={{backgroundColor: 'white'}}
          helperText={
            cadastro.conta.length === 0 ?
              '' :
              cadastro.conta.length < 6 ?
              'somente numeros' :
              ''
          }
          onChange={(e) => {
            setcadastro({...cadastro, conta: e.target.value});
          }}
        ></material.TextField>
        <material.TextField
          autoComplete="false"
          label="PIX"
          type="text"
          required
          size="small"
          value={cadastro.pix}
          color="primary"
          sx={{backgroundColor: 'white'}}
          onChange={(e) => {
            setcadastro({...cadastro, pix: e.target.value});
          }}
        ></material.TextField>

        <material.Typography
          component="span"
          sx={{color: 'gray', textAlign: 'center', display: 'block'}}
        >
          {invalidForm}
        </material.Typography>
        <material.Button
          startIcon={<SaveAltIcon />}
          size="small"
          variant="contained"
          sx={{
            color: themeJsx.theme.palette.textLight.main,
            backgroundColor: themeJsx.theme.palette.laranja.main,
          }}
          onClick={(e) => handleFormCadastro(e)}
        >
          Salvar
        </material.Button>
      </material.Stack>
    </themeJsx.SectionCadastro>
  );
}

export default Cadastro;
