import * as fire from "../../firebase/firestore";
import react from "react";
import CollapsibleTable from "./CollapsibleTable";

export default function Vencedor() {
  const menorPonto = react.useRef(null);
  const arrayResultadoMenorPontos = react.useRef([]);
  const arrayResultadoDezPontos = react.useRef([]);
  const arrayResultadoNovePontos = react.useRef([]);
  const [ganhadoresMenorPontuacao, setGanhadorMenorPonto] = react.useState([]);
  const [ganhadoresDezPontuacao, setGanhadorDezPonto] = react.useState([]);
  const [ganhadoresNovePontuacao, setGanhadorNovePonto] = react.useState([]);

  react.useEffect(() => {
    (async () => {
      const pontoRef = fire.doc(fire.projetcFirestore, "campeao/jogos");
      const docSnap = await fire.getDoc(pontoRef);
      let arrayDeGanhadoresMenorPonto = arrayPontos(docSnap)();
      generatorWinner(
        arrayDeGanhadoresMenorPonto,
        arrayResultadoMenorPontos,
        setGanhadorMenorPonto
      );

      let arrayDeGanhadoresComDezPonto = arrayPontos(docSnap, 10)();
      generatorWinner(
        arrayDeGanhadoresComDezPonto,
        arrayResultadoDezPontos,
        setGanhadorDezPonto
      );

      let arrayDeGanhadoresComNovePonto = arrayPontos(docSnap, 9)();
      if (Boolean(arrayDeGanhadoresComNovePonto) !== false) {
        generatorWinner(
          arrayDeGanhadoresComNovePonto,
          arrayResultadoNovePontos,
          setGanhadorNovePonto
        );
      }
      
    })();
  }, [arrayResultadoMenorPontos]);

  

  function arrayPontos(snapRef, val = 0) {
    let pontos = snapRef.data().pontos;
    let aux = val;
    if (aux === 8) return false;
    return function teste() {
      if (val === 10 || val === 9) {
        return pontos[val];
      } else {
        if (Object.keys(pontos).includes(`${aux}`)) {
          menorPonto.current = aux;
          return pontos[aux];
        } else {
          aux++;
          return teste();
        }
      }
    };
  }

  async function vencedorPonto(ref) {
    try {
      const [infoSnap] = await Promise.all([fire.getDoc(ref)]);

      return infoSnap;
    } catch (error) {
      // Handle any errors that occurred during the "getDoc()" method
      console.error(error);
    }
  }

  async function generatorWinner(
    arrayDeGanhadoresPonto,
    arrayResultadoPontos,
    setWinner
  ) {
    try {
      // Create an array of promises for the "getDoc()" method calls
      const promises = arrayDeGanhadoresPonto.map((valores, i) => {
        let usuarioRef = fire.doc(
          fire.projetcFirestore,
          `allGames/${valores.id}`
        );
        return vencedorPonto(usuarioRef);
      });
      // Wait for all "getDoc()" method calls to resolve
      await Promise.all(promises).then((documents) => {
        documents.forEach((document, index) => {
          let ganhador = {
            apelido: document.data().apelido,
            jogo: document.data().jogo[
              arrayDeGanhadoresPonto[index].indiceDoJogo
            ],
          };
          arrayResultadoPontos.current[index] = ganhador;
        });
      });
      setWinner(arrayResultadoPontos.current);
    } catch (error) {
      // Handle any errors that occurred during the "getDoc()" method calls
    }
  }

  return (
    <section style={{ blockSize:'100%'}}>
      {true && (
        <CollapsibleTable
          menorPonto={ganhadoresMenorPontuacao}
          dezPonto={ganhadoresDezPontuacao}
          novePonto={ganhadoresNovePontuacao}
        ></CollapsibleTable>
      )}
    </section>
  );
}
