import react from "react";
import * as firestoreJs from "../../firebase/firestore.js";
import useAuthContext from "../../hooks/useAuthContext";
import * as reactRouterDom from "react-router-dom";
import uniqid from "uniqid";
import * as material from "@mui/material";
import {theme} from "../../theme";
import ListNumberShowAcertos from "../../Componentes/UI/ListNumberShowAcertos";
import {PalpiteStyle} from "../../theme"

export default function Resultado() {
  const [isPending, setIsPending] = react.useState(true);
  const [palpite, setpalpite] = react.useState(null);
  const { user } = useAuthContext();

  const [resultado, setresultado] = react.useState(null);
  const [error, seterror] = react.useState("");


  react.useEffect(() => {  
     
          
         (async ()=>{
          let palpiteRef = firestoreJs.doc(
            firestoreJs.projetcFirestore,
            "/palpites/K3L1hWjr32f9PTOneAkF"
          );

          let alldata =  firestoreJs.doc(
            firestoreJs.projetcFirestore,
            `allGames/${user.uid}`
          );

         
          let palpiteData = await firestoreJs.getDoc(palpiteRef);
          let respon = await firestoreJs.getDoc(alldata)
          

          if (respon.exists()){

            setresultado(respon.data());
            setpalpite(palpiteData.data().palpite);
            
          }else{
            setTimeout(() => {
              seterror('Ops,algo deu errado. Você ja realizou algum jogo? Se sim, aguarde alguns minutos e caso ainda não apareça seu jogo entre em contato com suporte')
            }, 4000);
          }
          setIsPending(false);
        })()

        
   
  }, [isPending]);

  function RenderGameSorteio({ game, indice, beforeSorteio }) {
    if (beforeSorteio) {
      game = Object.values(game).map((val) => {
        return { value: val, selection: false };
      });
    }

    return (
      <material.Typography
        component="ul"
        key={uniqid()}
        sx={{
          paddingBlockEnd: "8px",
        }}
      >
        <material.Typography
          component="p"
          sx={{
            fontSize: theme.typography.fontSize,
            textAlign: "center",
            padding: "6px",
            display: "block",
          }}
        >
          Jogo {indice + 1 + ":"}
        </material.Typography>

        <material.Typography
          component="li"
          sx={{
            display: "inline-flex",
            flexDirection: "row",
            justifyContent: "space-around",
            flexWrap: "wrap",
            rowGap: "10px",
            inlineSize: "100%",
          }}
        >
          {Object.values(game).map((valor) => {
            return (
              <ListNumberShowAcertos
                key={uniqid()}
                paddStyle ={"2px 1px 1px 4px"}
                numero={valor.value}
                selecao={valor.selection}
                back={`${theme.palette.laranja.main}`}
                backselect={theme.palette.primary.main}
              ></ListNumberShowAcertos>
            );
          })}
        </material.Typography>
      </material.Typography>
    );

  }
  


  function Palpites() {
    return (
      <PalpiteStyle component="aside">
        <material.Container>
          <material.Typography component="span">Pontos</material.Typography>
          <material.List>
            {palpite.map((val, index) => {
              return (
                <material.ListItem key={uniqid()}>
                  {index !== 10 ? "0" + index : index}
                </material.ListItem>
              );
            })}
          </material.List>
        </material.Container>
        <material.Container>
          <material.Typography component="span">Jogos</material.Typography>
          <material.List>
            {palpite.map((val, index) => {
              return (
                <material.ListItem key={uniqid()}>
                  {val < 10 ? "0" + val : val}
                </material.ListItem>
              );
            })}
          </material.List>
        </material.Container>
      </PalpiteStyle>
    );
  }

  return (
    <>
      {!user && <reactRouterDom.Navigate to="/"></reactRouterDom.Navigate>}
      {isPending && <material.LinearProgress color="laranja" />}
      {!isPending && <material.Typography align="center" sx={{position:'absolute', insetBlockStart:'45%'}}>{error}</material.Typography>}

      {!isPending && (
        <section>
          {Boolean(palpite) && <>
          <material.Typography component='h3' align="center" sx={{backgroundColor: theme.palette.secundary.main, fontWeight:'400', color:'white'}}>Prévia Geral</material.Typography>
          <Palpites></Palpites>
          </>
          }
          <material.Typography component='h3'align="center" sx={{ paddingBlockStart: "8px" }}>
            Numeros Sorteados
          </material.Typography>
          {(
            <ul
              style={{
                display: "flex",
                flexDirection: "row",
                inlineSize: "100%",
                justifyContent: "space-around",
                paddingInline: "60px",
                flexWrap: "wrap",
              }}
            >
              {Boolean(resultado) &&  resultado.resultado
                .sort((a, b) => a - b)
                .map((val) => {
                  if (typeof val === "undefined") {
                    return [];
                  } else {
                    val = val < 10 ? "0" + val : val;
                    return (
                      <li
                        style={{
                          display: "inline",
                          borderRadius: "100%",
                          padding: "6px",
                          backgroundColor: theme.palette.laranja.main,
                          marginInline: "2px",
                          blockSize: "30px",
                        }}
                        key={uniqid()}
                      >
                        {val}
                      </li>
                    );
                  }
                })}
            </ul>
          )}
          <material.Typography
            component="h2"
            align="center"
            sx={{ paddingBlockStart: "8px" }}
          >
            Meus Jogos
          </material.Typography>
          <material.Typography
            component="section"
            sx={{
              "&>ul:nth-last-of-type(odd)": {
                backgroundColor: "wheat",
              },
            }}
          >
            {Boolean(resultado) && Object.values(resultado.acertos).length === 0 &&
              Object.values(resultado.jogo).map((game, index) => (
                <RenderGameSorteio
                  key={Math.random()}
                  game={game}
                  indice={index}
                  beforeSorteio={true}
                />
              ))}
            { Boolean(resultado) && Object.values(resultado.acertos).map((game, index) => (
              <RenderGameSorteio
                key={Math.random()}
                game={game}
                indice={index}
                beforeSorteio={false}
              />
            ))}
          </material.Typography>
        </section>
      )}
    </>
  );
}
