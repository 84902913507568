import {StyleDayWinNumber} from "../../theme"

export default function ListNumberShowAcertos({numero,back,backselect,selecao,paddStyle}){
    return (
        <StyleDayWinNumber
        p={paddStyle}
        back = {back}
        backselect = {`${backselect}`}
        component="ul"
        selected={selecao}
        divider={true}
        value={numero}
       
       
      >
        {numero < 10 ? <span> {"0"+numero }</span>: <span> {numero}</span>}
      </StyleDayWinNumber>
    )
}