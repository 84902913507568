import * as React from "react";
import PropTypes from "prop-types";
import * as material from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import uniqid from "uniqid";
import { theme } from "../../theme";
function createData(name, game) {
  let changeDatagame = game.map((value) => ({
    apelido: value.apelido,
    jogo: value.jogo,
  }));
  return {
    name,
    history: changeDatagame,
  };
}

function Row(props) {
  const { row } = props;
  const [open, setOpen] = React.useState(false);

  return (
    <React.Fragment>
      <material.TableRow
        sx={{
          "& > *": { borderBottom: "unset" },
          backgroundColor: theme.palette.laranja.main,
        }}
      >
        <material.TableCell>
          <material.IconButton
            aria-label="expand row"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </material.IconButton>
        </material.TableCell>
        <material.TableCell>{row.name}</material.TableCell>
      </material.TableRow>
      <material.TableRow>
        <material.TableCell
          style={{ paddingBottom: 0, paddingTop: 0 }}
          colSpan={6}
        >
          <material.Collapse in={open} timeout="auto" unmountOnExit>
            <material.Box sx={{ margin: 1 }}>
              <material.Typography variant="h6" gutterBottom component="div">
                Apelido e jogo Vencedor
              </material.Typography>
              <material.Table size="small" aria-label="purchases">
                <material.TableHead>
                  <material.TableRow>
                    <material.TableCell>Apelido</material.TableCell>
                    <material.TableCell>Jogo</material.TableCell>
                  </material.TableRow>
                </material.TableHead>
                <material.TableBody>
                  {row.history.map((historyRow, index) => (
                    <material.TableRow key={index}>
                      <material.TableCell component="th" scope="row">
                        {historyRow.apelido}
                      </material.TableCell>
                      <material.TableCell>
                        <material.List
                          sx={{
                            display: "inline-flex",
                            flexDirection: "row",
                            flexWrap: "wrap",
                          }}
                        >
                          {historyRow.jogo.map((number) => (
                            <material.ListItem
                              key={uniqid()}
                              sx={{
                                display: "inline",
                                inlineSize: "fit-content",
                              }}
                            >
                              {" "}
                              {number < 10 ? "0" + number : number}
                            </material.ListItem>
                          ))}
                        </material.List>
                      </material.TableCell>
                    </material.TableRow>
                  ))}
                </material.TableBody>
              </material.Table>
            </material.Box>
          </material.Collapse>
        </material.TableCell>
      </material.TableRow>
    </React.Fragment>
  );
}

Row.propTypes = {
  row: PropTypes.shape({
    history: PropTypes.arrayOf(
      PropTypes.shape({
        apelido: PropTypes.string.isRequired,
        jogo: PropTypes.array.isRequired,
      })
    ).isRequired,
  }).isRequired,
};

export default function CollapsibleTable({ menorPonto, dezPonto, novePonto }) {
  const rows = [
    createData("DEZ PONTOS", dezPonto),
    createData("NOVE PONTOS", novePonto),
    createData("MENOR PONTUAÇÃO", menorPonto),
  ];

  return (
    <material.TableContainer component={material.Paper} sx={{display: "flex",blockSize: "inherit",flexDirection: 'column'}}>
      <material.Table aria-label="collapsible table" >
        <material.TableBody >
          {rows.map((row) => (
            <Row key={row.name} row={row} />
          ))}
        </material.TableBody>
      </material.Table>
    </material.TableContainer>
  );
}
