import { ModalUser } from "../../theme";
import ClearIcon from "@mui/icons-material/Clear";
import { theme } from "../../theme";
import { Typography } from "@mui/material";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";

export default function ModalConfirmation({
  status,
  changeStatus,
  text,
  tringerFunction,
}) {
  return (
    <ModalUser
      sx={{
        blockSize: "50%",
        inlineSize: "70%",
        insetBlockStart: "25%",
        insetInlineStart: "15%",
      }}
      hideBackdrop={false}
      disableAutoFocus={true}
      open={status}
    >
      <section
        style={{
          blockSize: "100%",
          inlineSize: "100%",
          backgroundColor: theme.palette.laranja.main,
          position: "relative",
        }}
      >
        <ClearIcon
          sx={{
            position: "absolute",
            insetBlockStart: "5%",
            insetInlineEnd: "5%",
          }}
          onClick={() => changeStatus(false)}
        />
        <Typography
          component={"p"}
          sx={{
            color: "white",
            display: "flex",
            textAlign: "center",
            alignItems: "center",
            paddingInline: "8px",
            justifyContent: "center",
            position: "absolute",
            insetBlockStart: "20%",
            inlineSize: "100%",
            blockSize: "fit-content",
            fontSize: "1rem",
          }}
        >
          {text}
        </Typography>

        <Stack
          spacing={2}
          direction="column-reverse"
          sx={{
            display: "flex",
            inlineSize: "90%",
            position: "absolute",
            insetBlockEnd: "30%",
            marginInline: "5%",
            justifyContent: "center",
          }}
        >
          <Button variant="outlined" onClick={() => changeStatus(false)}>
            Cancelar
          </Button>
          <Button
          
            variant="contained"
            onClick={(e) => {
              e.preventDefault()
              tringerFunction(e);
              changeStatus(false);
              window.open("https://wa.me/5581988605663?text=pagamento", 'black')
            }}
          >
            Salvar e Pagar
          </Button>
        </Stack>
      </section>
    </ModalUser>
  );
}
