import * as reactRouterDom from "react-router-dom";
import * as material from "@mui/material";
import * as themeJsx from "../../theme.jsx";
import useAuthContext from "../../hooks/useAuthContext.jsx";
import LoginIcon from "@mui/icons-material/Login";
import HowToRegSharpIcon from "@mui/icons-material/HowToRegSharp";
import InfoSharpIcon from "@mui/icons-material/InfoSharp";

export default function Home() {
  const { user } = useAuthContext();
  const navigation = reactRouterDom.useNavigate();

  return (
    <>
      {user && <reactRouterDom.Navigate to="/user" replace={true} />}

      {!user && (
        <themeJsx.InforHome
          component={"section"}
          sx={{ position: "relative", blockSize: "100%" }}
        >
          <material.Stack
            spacing={4}
            justifyContent="space-between"
            sx={{ blockSize: "50%" }}
          >
            <h1>Ainda mais fácil é jogar com os amigos</h1>
            <p>
              Escolha dez numeros de 0 a 80. Sorteios serão diários e seus acertos
              são baseados nos sorteios da <mark>Quina</mark>. <br></br>
            </p>
            <h3 style={{ color: "green" }}>PREMIAÇÕES:</h3>
            <ul style={{ listStyleType: "none" }}>
              <li>
                <strong>
                  <em>PRÊMIO TOTAL.</em>
                </strong>{" "}
                : 10 pontos
              </li>
              <br></br>
              <li>PREMIO PARCIAL: 9 pontos.</li>
              <br></br>
              <li>PREMIO PARCIAL: Menor pontuação.</li>
              <br></br>
              <li>
                Quem fizer maior pontuação <strong>DIÁRIA</strong> tambem é
                premiado.
              </li>
            </ul>

            <material.Stack spacing={2}>
              <material.Button
                onClick={() => navigation("/login")}
                variant="contained"
                color="primary"
                startIcon={<LoginIcon />}
              >
                Logar
              </material.Button>
              <material.Button
                onClick={() => navigation("/singup")}
                variant="contained"
                color="primary"
                startIcon={<HowToRegSharpIcon />}
              >
                Cadastrar
              </material.Button>
              <material.Button
                variant="contained"
                color="primary"
                startIcon={<InfoSharpIcon />}
              >
                <a href="https://wa.me/5581988605663?text=informação" target='blank'>
                  Mais Informações
                </a>
              </material.Button>
            </material.Stack>
          </material.Stack>
        </themeJsx.InforHome>
      )}
    </>
  );
}
