import useAuthContext from "./useAuthContext";
import { projetcAuth, signOut } from "../firebase/auth";
export default function useLogout() {
  const { user, dispatch } = useAuthContext();

  async function logout() {
    try {
      if (user) {
        await signOut(projetcAuth);
        dispatch({ type: "LOGOUT", action : null });
        dispatch({ type: "isAdmin", action: false });
      }
    } catch (error) {
      console.log(error);
    }
  }

  return { logout };
}
