import * as material from "@mui/material";
import * as reactRouterDom from "react-router-dom";
import react from "react";

import GoogleIcon from "@mui/icons-material/Google";
import LoginIcon from "@mui/icons-material/Login";
import useLogin from "../hooks/useLogin";
import useSingUp from "../hooks/useSingup";
import useAuthContext from "../hooks/useAuthContext";
import { FormLogin } from "../theme";

export default function Login() {
  const navigate = reactRouterDom.useNavigate();
  const location = reactRouterDom.useLocation();

  const { user } = useAuthContext();
  const { login, error } = useLogin();
  const { singupWithGoogle } = useSingUp();
  const [email, setemail] = react.useState("");
  const [emailError, setemailError] = react.useState("");
  const [senhaError, setsenhaError] = react.useState("");
  const [password, setpassword] = react.useState("");
  const [erroNãoIdentificado, seterroNãoIdentificado] = react.useState("");

  react.useEffect(() => {
    
    if (error === "auth/invalid-email") {
      setemailError("e-mail inválido");
    } else if (error === "auth/user-not-found") {
      setemailError("e-mail não cadastrado");
    } else if (error === "auth/wrong-password") {
      setsenhaError("senha inválido");
    } else if (error === "auth/internal-error") {
      seterroNãoIdentificado("ops, algo esta errado");
    }
    setTimeout(() => {
      setemailError("");
      setsenhaError("");
      seterroNãoIdentificado("");
    }, 2000);
  }, [error]);

  async function loginHandler(event) {
    event.preventDefault();
    let response = await login(email, password);

    if (Boolean(response)) {
      navigate("../user", { replace: true });
    }
  }

  return (
    <section style={{ blockSize: "70%",inlineSize:'80%', marginInline:'auto', paddingBlockStart: "15%" }}>
      {!user && (
        <FormLogin>
          <material.Stack
            spacing={2}
            sx={{ blockSize: "100%", inlineSize:'100%', justifyContent: "space-evenly",alignItems: 'center' }}
          >
            <material.TextField
              autoComplete="false"
              label="email"
              type="email"
              helperText={emailError}
              required
              size="small"
              sx={{inlineSize:'inherit'}}
              value={email}
              onChange={(e) => setemail(e.target.value)}
            />

            <material.TextField
              autoComplete="false"
              label="senha"
              helperText={senhaError}
              type="password"
              required
              size="small"
              sx={{inlineSize:'inherit'}}
              value={password}
              onChange={(e) => setpassword(e.target.value)}
            />
            <span>{erroNãoIdentificado}</span>

            <material.Button
              variant="contained"
              color="primary"
              startIcon={<LoginIcon />}
              sx={{inlineSize:'inherit'}}
              onClick={(e) => {
                e.preventDefault();
                loginHandler(e);
              }}
            >
              Logar
            </material.Button>
            <material.Button
              variant="contained"
              color="primary"
              sx={{inlineSize:'inherit'}}
              startIcon={<GoogleIcon />}
              onClick={(e) => {
                e.preventDefault();
                singupWithGoogle();
              }}
            >
              Logar com Google
            </material.Button>
            <material.Link
              target="_blank"
              href="https://wa.me/5531980384094?text=senha"
              underline="hover"
              color="inherit"
            >
              Esqueci minha senha
            </material.Link>
          </material.Stack>
        </FormLogin>
      )}
      {user && (
        <reactRouterDom.Navigate
          to="/user"
          replace={true}
          state={location}
        ></reactRouterDom.Navigate>
      )}
    </section>
  );
}
